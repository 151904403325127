<template>
  <div class="container bg-e0 p-3" v-if="!emptyPartners">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="text-center partner-title">
          <span class="text-danger px-sm-1">شركاؤنا</span
          ><span id="partner-text">الإستراتيجيون</span>
        </h4>
      </div>
    </div>
    <div class="row text-center text-lg-left">
      <div
        class="col-md-3 col-6 p-2"
        v-for="partner in partners"
        :key="partner.id"
      >
        <PartnerCard :partner="partner" />
      </div>
    </div>
  </div>
</template>

<script>
import types from "@/common/types";
import PartnerCard from "@/components/UI/PartnerCard";

export default {
  components: { PartnerCard },
  computed: {
    partners() {
      return types.objectToArray(this.$store.getters.partners);
    },
    emptyPartners() {
      return !this.partners || this.partners.length === 0;
    },
  },
  async mounted() {
    await this.$store.dispatch("setPartnersAction");
  },
};
</script>

<style scoped>
@media (max-width: 300px) {
  #partner-text {
    display: none;
  }

  .partner-title {
    font-size: 20px;
  }
}
</style>
