import {createToast} from "mosha-vue-toastify"
import axios from "axios"
import messages from "./messages"
import store from "@/store"
import router from "@/router"

const HttpRequest = class HttpRequest {
  inputsId

  constructor() {
    $_(document).on('change keyup', '.form-control, select, input, textarea', function () {
      $_(this).removeClass('is-invalid')
      $_('#' + $_(this).attr('id') + '_error').text('')
    });
  }

  setUrl(base, url) {
    // url cases
    // 1- base url does not end with '/' and the payload.url does not start with /
    // 2- base url ends with '/' and the payload.url does not start with /
    // 3- base url ends with '/' and the payload.url start with /
    if (!base.endsWith('/') && !url.startsWith('/')) {
      url = base + '/' + url
    } else if ((base.endsWith('/') && !url.startsWith('/')) || (!base.endsWith('/') && url.startsWith('/'))) {
      url = base + url
    } else if (base.endsWith('/') && url.startsWith('/')) {
      url = base + url.substring(1)
    } else {
      url = false
    }

    return url
  }

  async send(url, data = {}, config = {}) {
    // CHECK IF LINKS ARE CORRECT
    if (!baseURL || !url) {
      this.errorMessage(messages.linkError)
      return
    }
    // RESET ERRORS
    const error = $_('.form-error')
    const inputs = $_('.form-control, select, textarea, input')

    error.text('')
    inputs.removeClass('is-invalid')

    // SHOW PRELOADER
    await store.dispatch('setShouldReloadAction', true)
    let response

    try {
      // Set Base Url
      url = this.setUrl(baseURL, url)

      const headers = !!config && !!config.headers ? config.headers : {}
      config.headers = {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': (`Bearer ${store.getters.token}`),
        ...headers
      }

      response = await axios.post(url, data, config)
    } catch (e) {
      // STOP PRELOADER
      await store.dispatch('setShouldReloadAction', false)
      // HANDLE THE ERROR
      if (!!e.response) {
        response = e.response
      }

      await this.handleError(response)
    }

    // STOP PRELOADER
    await store.dispatch('setShouldReloadAction', false)
    return response
  }

  popupMessage(type, text, timeout = 10000, showIcon = true, hideProgressBar = true, swipeClose = true) {
    if (!type || !text)
      return

    createToast(text, {type, timeout, showIcon, hideProgressBar, swipeClose})
  }

  errorMessage(text) {
    this.popupMessage('error', text)
  }

  successMessage(text) {
    this.popupMessage('success', text)
  }

  infoMessage(text) {
    this.popupMessage('info', text)
  }

  async handleError(response) {
    const status = response ? response.status : ''
    let msg = ''

    switch (status) {
      case 409:
        msg = 'هذا الحساب قيد تسجيل الدخول'
        break
      case 503:
        msg = 'البيانات المدخلة خاطئة'
        break
      case 500:
        msg = 'خطأ غير متوقع'
        break
      case 422:
        if (!!response && response.data) {
          await this.printErrors(response.data.errors)
        }

        msg = messages.checkFields
        if (response.data && response.data.msg) {
          msg = response.data.msg
        }
        break
      case 404:
        await router.replace({name: 'not.found'})
        msg = 'الصفحة غير موجودة'
        break
      case 403:
        msg = 'لا تملك الصلاحية'
        break
      case 401:
        if (!!store.getters.authorized) {
          msg = 'يرجى تسجيل الدخول للمتابعة'
        }

        await store.dispatch('clearAuthorized')
        await router.replace('/')
        break
    }

    if (msg) {
      this.popupMessage('error', msg)
    }
  }

  printErrors(errors) {
    if (!errors) {
      return
    }

    const keys = Object.keys(errors)

    if (!!keys && keys.length > 0) {
      this.scrollToElement(`#${keys[0]}`)
    }

    $_.each(errors, (key, val) => {
      key = !!this.inputsId ? (key + this.inputsId) : key

      const label = $_(`#${key}_error`)
      const input = $_(`#${key}`)

      input.addClass('is-invalid')
      label.text(Array.isArray(val) ? val[0] : val)

      if (input.hasClass('selectpicker')) {
        input.selectpicker('refresh')
      }
    });

    this.inputsId = ''
  }

  responseAccepted(response, valid = false) {
    let check = !!response && !!response.data && response.status === 200

    if (!!response && valid) {
      check = check && !!response.data[valid]
    }

    return check
  }

  scrollToElement(selector, alt = '_btn') {
    if (!selector) {
      return
    }

    let element = $_(selector)
    element = !!element && !element.hasClass('hide') && !element.attr('hidden') ? element : (!!alt ? $_(selector + alt) : '')

    if (!!element) {
      const offset = element.offset()

      if (!!offset) {
        $_('html, body').animate({
          scrollTop: offset.top - (element.height() / 2),
        }, 500)
      }
    }
  }
}

const http = new HttpRequest()
export default http