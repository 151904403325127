import Storage from "@/common/Storage";
import arrays from "@/common/arrays";

export default {
  data() {
    return {
      infoList: [],
      page: "",
    };
  },
  computed: {
    storageName() {
      return !!this.depart ? `information-${this.depart}` : "";
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    routeName() {
      return this.$store.getters.currentRoute;
    },
  },
  methods: {
    async setInfo() {
      if (!this.depart) return;
      this.infoList = Storage.get(this.storageName);
      if (!!this.infoList) return (this.infoList = this.infoList.data);
      // depart 4 => footer
      for (let i = 1; i <= 3; i++) Storage.remove(`information-${i}`);
      const response = await http.send("information", {
        _method: "GET",
        depart: this.depart,
      });
      if (http.responseAccepted(response, "info")) this.saveInfoData(response);
    },
    async store(e) {
      if (!this.depart) return console.error("no depart specified");
      if (
        !$_("#info").val() ||
        (this.routeName === "about" && !$_("#flag").val())
      )
        return http.popupMessage("error", messages.checkFields);
      const formData = new FormData(e.target);
      formData.set("depart", this.depart);
      const response = await http.send("information", formData);
      if (http.responseAccepted(response, "info")) {
        http.popupMessage("success", messages.saved);
        this.saveInfoData(response, `#add-${this.page}`);
        $_(".form-control").val("");
        $_(".selectpicker").selectpicker("val", "");
      }
    },
    async update(e) {
      if (!this.depart) return console.error("no depart specified");
      const formData = new FormData(e.target);
      if (!formData) return console.error("form data is empty");
      const id = formData.get("id");
      if (!id) {
        console.error("id is not specified");
      } else if (!formData.get("info")) {
        http.popupMessage("error", messages.checkFields);
      } else {
        http.inputsId = id;
        formData.set("depart", this.depart);
        const response = await http.send(`information/${id}`, formData);
        if (http.responseAccepted(response, "info")) {
          http.popupMessage("success", messages.saved);
          this.saveInfoData(response, `#edit_${this.page}${id}`);
        }
      }
      http.inputsId = "";
    },
    saveInfoData(response, modal) {
      if (!!modal) this.hideModal(modal);
      this.infoList = response.data.info;
      Storage.set(this.storageName, this.infoList, -1); //done
    },
    deleted(id) {
      if (!id || !this.infoList || this.infoList.length === 0) return;
      this.infoList = arrays.removeById(this.infoList, id);
      Storage.set(this.storageName, this.infoList, -1);
    },
  },
};
