<template>
  <div class="container pb-4">
    <div class="row">
      <div class="col-md-12">
        <base-card>
          <template #header>من نحن</template>
          <template #body>
            <div class="row my-0" v-if="superAdmin">
              <div class="col-sm-12 d-flex justify-content-end">
                <modal-btn
                  title="إضافة"
                  target="#add-about"
                  btn-class="btn-sm btn-info shadow"
                >
                  <i class="fa fa-plus"></i>
                </modal-btn>
              </div>
            </div>
            <div
              v-for="item in infoList"
              :key="item.id"
              :id="`about-row${item.id}`"
              class="pm-0"
            >
              <div v-if="superAdmin" class="text-left">
                <button
                  type="button"
                  data-toggle="modal"
                  :data-target="`#edit_about${item.id}`"
                  class="btn bg-transparent"
                >
                  <i class="fa fa-edit text-info fa-1x"></i>
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  :data-target="`#delete_about${item.id}`"
                  class="btn bg-transparent"
                >
                  <i class="fa fa-trash text-danger fa-1x"></i>
                </button>
              </div>
              <p class="pre-wrap pm-0" :class="{ h4: item.flag === 2 }">
                {{ item.info }}
              </p>
              <teleport to="#app" v-if="superAdmin">
                <items-destroyer
                  :target-id="`delete_about${item.id}`"
                  title="حذف نص"
                  :row-id="`about-row${item.id}`"
                  :url="`information/${item.id}`"
                  @deleted="deleted"
                >
                  <span>هل أنت متأكد من حذف</span>&nbsp;<span class="link">{{
                    item.info
                  }}</span
                  >&nbsp;؟
                </items-destroyer>
                <confirm-model
                  :target-id="`edit_about${item.id}`"
                  title="تعديل نص"
                  confirm-text="تعديل"
                  @confirmed="clickById(`edit-about-submit${item.id}`)"
                >
                  <form @submit.prevent="update">
                    <input type="hidden" name="id" :value="item.id" />
                    <input type="hidden" name="_method" value="PUT" />
                    <div class="row">
                      <div class="col-sm-12">
                        <label class="link">النص</label>
                        <form-text-area
                          :id="`info${item.id}`"
                          name="info"
                          placeholder="النص"
                          :value="item.info"
                        ></form-text-area>
                      </div>
                    </div>
                    <div class="row" v-if="!!flags">
                      <div class="col-sm-12">
                        <label class="link">نوع النص</label>
                        <bootstrap-select
                          :id="`flag${item.id}`"
                          name="flag"
                          :search="false"
                          :empty-option="false"
                          :options="flags"
                          :selected="item.flag"
                        ></bootstrap-select>
                      </div>
                    </div>
                    <button hidden :id="`edit-about-submit${item.id}`"></button>
                  </form>
                </confirm-model>
              </teleport>
            </div>
          </template>
        </base-card>
      </div>
    </div>
    <teleport to="#app" v-if="superAdmin">
      <confirm-model
        title="إضافة"
        confirm-text="إضافة"
        target-id="add-about"
        @confirmed="clickById('add-about-submit')"
      >
        <form @submit.prevent="store">
          <div class="row">
            <div class="col-sm-12">
              <label class="link">النص</label>
              <form-text-area id="info" placeholder="النص"></form-text-area>
            </div>
          </div>
          <div class="row" v-if="!!flags">
            <div class="col-sm-12">
              <label class="link">نوع النص</label>
              <bootstrap-select
                id="flag"
                :search="false"
                :empty-option="false"
                :options="flags"
              ></bootstrap-select>
            </div>
          </div>
          <button hidden id="add-about-submit"></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import infoList from "@/mixins/infoList";
import BaseCard from "../../UI/BaseCard";
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormTextArea from "@/components/Forms/FormTextArea";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";

export default {
  components: {
    ItemsDestroyer,
    BootstrapSelect,
    FormTextArea,
    ConfirmModel,
    ModalBtn,
    BaseCard,
  },
  mixins: [infoList],
  computed: {
    depart() {
      return 3;
    },
    flags() {
      return [
        { id: 1, content: "نص" },
        { id: 2, content: "عنوان" },
      ];
    },
  },
  async created() {
    this.page = "about";
    await this.setInfo();
  },
};
</script>