<template>
  <TheHeader />
  <div class="wrapper" :class="{ 'pt-0': indexPage }">
    <router-view />
  </div>
  <TheFooter />
</template>

<script>
import TheHeader from "@/components/layouts/Home/TheHeader";
import TheFooter from "@/components/layouts/Home/TheFooter";

export default {
  components: { TheFooter, TheHeader },
  computed: {
    indexPage() {
      return ["/", "/auctions"].includes(this.$route.path);
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 3rem 0;
}
</style>
