import user from "./user";
import messages from "@/common/messages";

const commonRoutes = [
  /** MAIN ROUTES **/
  {
    path: "/bit/:keyId",
    component: () => import("@/components/Url/Url"),
  },
  {
    path: "/",
    name: "home",
    component: require("@/components/Pages/HomePage").default,
    children: [
      {
        path: "",
        name: "index",
        meta: { suspended: true, title: messages.joauctions },
        component: require("@/components/Pages/Auctions/Lists/Index.vue").default,
      },
      {
        path: "/auctions",
        name: "auctions.index",
        meta: { suspended: true, title: messages.joauctions },
        component: require("@/components/Pages/Auctions/Lists/Index.vue").default,
      },
      {
        path: "/about",
        name: "about",
        meta: { suspended: true, title: "من نحن" },
        component: require("@/components/Pages/Info/AboutUs").default,
      },
      {
        path: "/guides",
        name: "guides",
        meta: { suspended: true, title: "الية الشراء" },
        component: require("@/components/Pages/Info/PurchaseMechanism").default,
      },
      {
        path: "/questions",
        name: "questions",
        meta: { suspended: true, title: "الأسئلة الشائعة" },
        component: require("@/components/Pages/Info/FrequentlyQuestion")
          .default,
      },
      {
        path: "/contact",
        name: "contact",
        meta: { title: "إتصل بنا" },
        component: require("@/components/Pages/Info/ContactUs").default,
      },
      {
        path: "/login",
        name: "login",
        meta: { requiresUnAuth: true, title: "تسجيل الدخول" },
        component: require("@/components/Pages/Auth/Login").default,
      },
      {
        path: "/register",
        name: "register",
        meta: { requiresUnAuth: true, title: "اشتراك جديد" },
        component: require("@/components/Pages/Auth/Registration").default,
      },
      {
        path: "/forget-password",
        name: "forget.password",
        meta: { requiresUnAuth: true, title: "إستعادة كلمة المرور" },
        component: () => import("@/components/Pages/Recovery/ForgetPassword"),
      },
      {
        path: "/reset-password",
        name: "reset.password",
        meta: { requiresUnAuth: true, title: "إستعادة كلمة المرور" },
        component: () => import("@/components/Pages/Recovery/ResetPassword"),
      },
      {
        path: "/change-password",
        name: "change.password",
        meta: { requiresAuth: true, title: "تغيير كلمة المرور" },
        component: () => import("@/components/Pages/Recovery/ChangePassword"),
      },
      {
        path: "stumbled",
        name: "user.stumbled",
        meta: { title: "الحساب محجوز" },
        component: () => import("@/components/Pages/Errors/UserStumbled"),
      },
      {
        path: "verify-mobile",
        name: "should.verify.mobile",
        meta: { title: "تأكيد الهاتف" },
        component: () => import("@/components/Pages/Recovery/VerifyMobile"),
      },
      {
        path: "update-data",
        name: "should.update.data",
        meta: { title: "تحديث البيانات" },
        component: () => import("@/components/Pages/Errors/shouldUpdateData"),
      },
      {
        path: "bids/:auction",
        name: "auctions.bid",
        meta: { title: "الاردنية الاولى للمزادات" },
        component: () => import("@/components/Pages/Bids/AuctionBid"),
      },
      {
        path: "privacy",
        name: "privacy",
        meta: { title: "بيان الخصوصية" },
        component: () => import("@/components/Pages/Info/PrivacyStatement"),
      },
      {
        path: "conditions",
        name: "conditions",
        meta: { title: "الشروط والأحكام" },
        component: () => import("@/components/Pages/Info/TermsConditions"),
      },
      ...user,
      {
        path: "/:notFound(.*)*",
        name: "not.found",
        meta: { title: "الصفحة غير موجودة" },
        component: () => import("@/components/Errors/NotFound"),
      },
    ],
  },
];

export default commonRoutes;
