import auctionsRoutes from "@/router/routes/auctions";

const adminRoutes = [
  {
    path: "/admin",
    meta: { requiresAuth: true, admin: true },
    component: () => import("@/components/Pages/Admin/AdminHome"),
    children: [
      {
        path: "/users",
        meta: { title: "المستخدمين" },
        component: () => import("@/components/Users/AdminUsersHome"),
        children: [
          {
            path: "",
            name: "users.list",
            component: () => import("@/components/Pages/User/UsersList"),
          },
          {
            path: "create",
            name: "users.create",
            meta: { title: "إنشاء مستخدم", canCreateUser: true },
            component: () => import("@/components/Pages/Auth/Registration"),
          },
          {
            path: ":user",
            name: "users.edit",
            redirect: { name: "users.info" },
            meta: { title: "تعديل مستخدم", canUpdateUser: true },
            component: () => import("@/components/Pages/User/EditUser"),
            children: [
              {
                path: "info",
                name: "users.info",
                meta: { title: "بيانات المستخدم" },
                component: () => import("@/components/Pages/User/UserInfo"),
              },
              {
                path: "images",
                name: "users.images",
                meta: { title: "صور المستخدم" },
                component: () => import("@/components/Pages/User/UserImages"),
              },
              {
                path: "mobiles",
                name: "users.mobiles",
                meta: { title: "هواتف المستخدم" },
                component: () => import("@/components/Pages/User/UserMobiles"),
              },
              {
                path: "status",
                name: "users.status",
                meta: { title: "حالة الحساب" },
                component: () => import("@/components/Pages/User/UserStatus"),
              },
              {
                path: "auctions",
                name: "users.auctions",
                redirect: { name: "user.auctions.following" },
                meta: { title: "المزادات" },
                component: () =>
                  import("@/components/Pages/User/UserEditAuctions"),
                children: [
                  {
                    path: "following",
                    name: "user.auctions.following",
                    meta: { title: "المزادات التي يتابعها" },
                    component: () =>
                      import("@/components/Pages/User/Auctions/Following"),
                  },
                  {
                    path: "won",
                    name: "user.auctions.won",
                    meta: { title: "المزادات التي فاز بها" },
                    component: () =>
                      import("@/components/Pages/User/Auctions/Won"),
                  },
                  {
                    path: "user-unpaid",
                    name: "user.auctions.user-unpaid",
                    meta: { title: "بحاجة الى تسديد" },
                    component: () =>
                      import("@/components/Pages/User/Auctions/UserUnpaid"),
                  },
                  {
                    path: "lost",
                    name: "user.auctions.lost",
                    meta: { title: "خسر فيها" },
                    component: () =>
                      import("@/components/Pages/User/Auctions/Lost"),
                  },
                  {
                    path: "user-stumbled",
                    name: "user.auctions.user-stumbled",
                    meta: { title: "مزادات متعثرة" },
                    component: () =>
                      import("@/components/Pages/User/Auctions/UserStumbled"),
                  },
                  {
                    path: "user-unpaid-floor",
                    name: "user.auctions.user-unpaid-floor",
                    meta: { title: "مزادات متعثرة" },
                    component: () =>
                      import(
                        "@/components/Pages/User/Auctions/UserUnpaidFloor"
                      ),
                  },
                  {
                    path: "has-reassigned",
                    name: "user.auctions.has-reassigned",
                    meta: { title: "تم إعادة تعيينها" },
                    component: () =>
                      import(
                        "@/components/Pages/User/Auctions/HasReassigned"
                      ),
                  },
                  {
                    path: "user-current",
                    name: "user.auctions.user-current",
                    meta: { title: "مزادات الحالية" },
                    component: () => import("@/components/Pages/User/Auctions/Current"),
                  },
                ],
              },
              {
                path: "blocks",
                name: "users.blocks",
                meta: { title: "حظر المستخدم" },
                component: () => import("@/components/Pages/User/UserBlocks"),
              },
              {
                path: "logs",
                name: "users.logs",
                meta: { title: "نشاطات المستخدم" },
                component: () => import("@/components/Pages/User/UserLogs"),
              },
            ],
          },
          {
            path: "activation",
            name: "users.activation",
            meta: { title: "طلبات التفعيل", canActivateUser: true },
            component: () =>
              import("@/components/Pages/Admin/ActivationRequests"),
          },
        ],
      },
      {
        path: "/assistants",
        meta: { title: "المساعدين", superAdmin: true },
        component: () => import("@/components/Users/AdminUsersHome"),
        children: [
          {
            path: "",
            name: "assistants.list",
            meta: { superAdmin: true },
            component: () => import("@/components/Pages/User/UsersList"),
          },
          {
            path: "create",
            name: "assistants.create",
            meta: { title: "إنشاء مساعد", superAdmin: true },
            component: () =>
              import("@/components/Pages/Assistants/CreateAssistants"),
          },
          {
            path: ":user",
            name: "assistants.page",
            redirect: { name: "assistants.edit" },
            meta: { title: "تعديل مساعد", superAdmin: true },
            component: () =>
              import("@/components/Pages/Assistants/AssistantEditHome"),
            children: [
              {
                path: "edit",
                name: "assistants.edit",
                meta: { title: "بيانات المساعد", superAdmin: true },
                component: () =>
                  import("@/components/Pages/Assistants/EditAssistant"),
              },
            ],
          },
        ],
      },
      {
        path: "/dashboard",
        name: "admin.dashboard",
        meta: { title: "لوحة التحكم" },
        component: () => import("@/components/Pages/Admin/AdminDashboard"),
      },
      {
        path: "/admin/profile",
        name: "admin.profile",
        meta: { title: "بياناتي" },
        component: () => import("@/components/Pages/Admin/AdminProfile"),
      },
      {
        path: "/companies",
        name: "companies",
        meta: { title: "الشركات", superAdmin: true },
        component: () => import("@/components/Pages/AuctionsLists/DataLists"),
      },
      {
        path: "/notes",
        name: "notes",
        meta: { title: "الملاحظات", superAdmin: true },
        component: () => import("@/components/Pages/AuctionsLists/DataLists"),
      },
      {
        path: "/insurances",
        name: "insurances.list",
        meta: { title: "شرائح التأمين", superAdmin: true },
        component: () =>
          import("@/components/Pages/AuctionsLists/InsurancesList"),
      },
      {
        path: "/strategic-partners",
        name: "strategic.partners",
        meta: { title: "شركاؤنا الاستراتيجيون", superAdmin: true },
        component: () =>
          import("@/components/Pages/AuctionsLists/StrategicPartners"),
      },
      {
        path: "/cars",
        name: "cars",
        meta: { title: "السيارات", superAdmin: true },
        component: () => import("@/components/Pages/Admin/Manufacturers"),
      },
      {
        path: "/colors",
        name: "colors",
        meta: { title: "السيارات", superAdmin: true },
        component: () => import("@/components/Pages/Admin/CarColors"),
      },
      {
        path: "/cars/:car/categories",
        name: "cars.categories",
        meta: { title: "فئات المركبة", superAdmin: true },
        component: () =>
          import("@/components/Pages/Admin/ManufacturersCategories"),
      },
      {
        path: "/companies/:company/payment",
        name: "companies.payment",
        meta: { title: "دفعات الشركة", superAdmin: true },
        component: () => import("@/components/Pages/Companies/Payments"),
      },
      {
        path: "/pricing/:company",
        name: "companies.pricing",
        meta: { title: "التسعير", superAdmin: true },
        component: () => import("@/components/Pages/Companies/Pricing"),
        redirect: { name: "winch.pricing" },
        children: [
          {
            path: "winch",
            name: "winch.pricing",
            meta: { title: "أسعار الونشات" },
            superAdmin: true,
            component: () => import("@/components/Pages/Companies/WinchPrices"),
          },
          {
            path: "fees",
            name: "fees.pricing",
            meta: { title: "عمولات المزاد", superAdmin: true },
            component: () => import("@/components/Pages/Companies/FeesPrices"),
          },
        ],
      },
      {
        path: "/accountant",
        name: "accountant",
        meta: { requiresAuth: true, admin: true, title: "الفواتير" },
        component: () => import("@/components/Pages/Invoices/InvoicesList"),
      },
      {
        path: "/incoming-payments",
        name: "incoming.payments",
        meta: { requiresAuth: true, admin: true, title: "الدفعات الواردة" },
        component: () =>
          import("@/components/Pages/Accounting/IncomingPayments"),
      },
      {
        path: "/invoice-config",
        name: "invoice.config",
        meta: { requiresAuth: true, admin: true, title: "اعدادات الفوتير" },
        component: () => import("@/components/Pages/Admin/InvoiceConfig"),
      },
      {
        path: "/charts",
        name: "charts.home",
        redirect: { name: "charts.index" },
        meta: { requiresAuth: true, admin: true, title: "احصائيات" },
        component: () => import("@/components/Pages/Charts/Home"),
        children: [
          {
            path: "",
            name: "charts.index",
            meta: { requiresAuth: true, admin: true, title: "احصائيات" },
            component: () => import("@/components/Pages/Charts/Index"),
          },
          {
            path: "users",
            name: "charts.users",
            meta: {
              requiresAuth: true,
              admin: true,
              title: "احصائيات المستخدمين",
            },
            component: () => import("@/components/Pages/Charts/Users"),
          },
        ],
      },
      ...auctionsRoutes,
    ],
  },
];

export default adminRoutes;
