<template>
  <div class="container pb-4">
    <div class="row">
      <div class="col-md-12">
        <base-card>
          <template #header>آلية الشراء</template>
          <template #body>
            <div class="row">
              <div class="col-sm-12 text-left">
                <h5>العملية بغاية البساطة</h5>
                <h6>فبعد اتمامك لعملية التسجيل، ودفعك لرسوم التسجيل وإيداعك لمبلغ التأمين، ما عليك سوى :</h6>
              </div>
            </div>
            <div class="row" v-if="superAdmin">
              <div class="col-2">
                <modal-btn title="إضافة" target="#add-guide" btn-class="btn-sm btn-info shadow">
                  <i class="fa fa-plus"></i>
                </modal-btn>
              </div>
            </div>
            <div v-for="(item, index) in infoList" :key="item.id" :id="`guide-row${item.id}`" class="d-flex justify-content-between"
                 :class="superAdmin ? 'my-2' : 'my-3'">
              <span class="pre-wrap">{{parseInt(index) + 1}}- {{ item.info }}</span>
              <div class="d-flex justify-content-center pm-0" v-if="superAdmin">
                <button type="button" data-toggle="modal" :data-target="`#edit_guide${item.id}`"
                        class="btn bg-transparent">
                  <i class="fa fa-edit text-info fa-1x"></i>
                </button>
                <button type="button" data-toggle="modal" :data-target="`#delete_guide${item.id}`"
                        class="btn bg-transparent">
                  <i class="fa fa-trash text-danger fa-1x"></i>
                </button>
              </div>
              <teleport to="#app" v-if="superAdmin">
                <items-destroyer
                  :target-id="`delete_guide${item.id}`"
                  title="حذف نص"
                  :row-id="`guide-row${item.id}`"
                  :url="`information/${item.id}`"
                  @deleted="deleted">
                  <span>هل أنت متأكد من حذف</span>&nbsp;<span class="link">{{ item.info }}</span>&nbsp;؟
                </items-destroyer>
                <confirm-model
                  :target-id="`edit_guide${item.id}`"
                  title="تعديل نص"
                  confirm-text="تعديل"
                  @confirmed="clickById(`edit-guide-submit${item.id}`)">
                  <form @submit.prevent="update">
                    <input type="hidden" name="id" :value="item.id">
                    <input type="hidden" name="_method" value="PUT">
                    <label class="link">النص</label>
                    <form-text-area :id="`info${item.id}`" name="info" placeholder="النص" :value="item.info"></form-text-area>
                    <button hidden :id="`edit-guide-submit${item.id}`"></button>
                  </form>
                </confirm-model>
              </teleport>
            </div>
          </template>
        </base-card>
      </div>
    </div>
    <teleport to="#app" v-if="superAdmin">
      <confirm-model
        title="إضافة"
        confirm-text="إضافة"
        target-id="add-guide"
        @confirmed="clickById('add-guide-submit')">
        <form @submit.prevent="store">
          <label class="link">النص</label>
          <form-text-area id="info" placeholder="النص"></form-text-area>
          <button hidden id="add-guide-submit"></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import BaseCard from "../../UI/BaseCard";
import infoList from "@/mixins/infoList";
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormTextArea from "@/components/Forms/FormTextArea";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";

export default {
  components: {ItemsDestroyer, FormTextArea, ConfirmModel, ModalBtn, BaseCard},
  mixins: [infoList],
  computed: {
    depart() {
      return 2;
    },
  },
  methods: {},
  async created() {
    this.page = 'guide';
    await this.setInfo();
  }
}
</script>