<template>
  <div class="container pb-4">
    <div class="row">
      <div class="col-md-12">
        <base-card>
          <template #header>اتصل بنا</template>
          <template #body>
            <div class="row my-0" v-if="superAdmin">
              <div class="col-2">
                <modal-btn
                  title="إضافة"
                  target="#add-contact"
                  btn-class="btn-sm btn-info shadow"
                >
                  <i class="fa fa-plus"></i>
                </modal-btn>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div
                  v-for="item in infoList"
                  :key="item.id"
                  :id="`contact-row${item.id}`"
                  class="d-flex justify-content-between pm-0"
                >
                  <span class="my-1">{{ item.info }}</span>
                  <div
                    class="d-flex justify-content-center pm-0"
                    v-if="superAdmin"
                  >
                    <button
                      type="button"
                      data-toggle="modal"
                      :data-target="`#edit_contact${item.id}`"
                      class="btn bg-transparent"
                    >
                      <i class="fa fa-edit text-info fa-1x"></i>
                    </button>
                    <button
                      type="button"
                      data-toggle="modal"
                      :data-target="`#delete_contact${item.id}`"
                      class="btn bg-transparent"
                    >
                      <i class="fa fa-trash text-danger fa-1x"></i>
                    </button>
                  </div>
                  <teleport to="#app" v-if="superAdmin">
                    <items-destroyer
                      :target-id="`delete_contact${item.id}`"
                      :row-id="`contact-row${item.id}`"
                      :url="`information/${item.id}`"
                      title="حذف نص"
                      @deleted="deleted"
                    >
                      <span>هل أنت متأكد من حذف</span>&nbsp;
                      <span class="link">{{ item.info }}</span
                      >&nbsp;؟
                    </items-destroyer>
                    <confirm-model
                      :target-id="`edit_contact${item.id}`"
                      title="تعديل نص"
                      confirm-text="تعديل"
                      @confirmed="clickById(`edit-contact-submit${item.id}`)"
                    >
                      <form @submit.prevent="update">
                        <input type="hidden" name="id" :value="item.id" />
                        <input type="hidden" name="_method" value="PUT" />
                        <label class="link">النص</label>
                        <form-input
                          :id="`info${item.id}`"
                          name="info"
                          placeholder="النص"
                          :value="item.info"
                        ></form-input>
                        <button
                          hidden
                          :id="`edit-contact-submit${item.id}`"
                        ></button>
                      </form>
                    </confirm-model>
                  </teleport>
                </div>
              </div>
              <div class="col-md-6">
                <AuctionsMap />
              </div>
            </div>
            <hr />
            <p class="text-danger text-left font-weight-bolder">
              أو يمكنك عن طريق البريد
            </p>
            <form @submit.prevent="feedback">
              <div class="row">
                <div class="col-md-6">
                  <FormInput
                    id="name"
                    text="الإسم الكامل"
                    :disabled="!!authorized && !!authorized.name"
                    :value="!!authorized ? authorized.name : ''"
                    :input-class="{ 'empty-on-submit': !authorized }"
                  />
                </div>
                <div class="col-md-6">
                  <label>البريد الالكتروني</label>
                  <FormInput
                    id="email"
                    placeholder="البريد الالكتروني"
                    :value="!!authorized ? authorized.email : ''"
                    :input-class="{ 'empty-on-submit': !authorized }"
                    :disabled="!!authorized && !!authorized.email"
                  />
                </div>
              </div>
              <hr />
              <div class="row pt-2">
                <div class="col-md-12">
                  <label>الرسالة</label>
                  <form-text-area
                    id="msg"
                    placeholder="أكتب رسالتك..."
                    input-class="empty-on-submit"
                  ></form-text-area>
                </div>
              </div>
              <div class="row p-0 justify-content-center">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-dark nr-block">
                    إرسال
                  </button>
                </div>
              </div>
            </form>
          </template>
        </base-card>
      </div>
    </div>
  </div>
  <teleport to="#app" v-if="superAdmin">
    <confirm-model
      title="إضافة"
      confirm-text="إضافة"
      target-id="add-contact"
      @confirmed="clickById('add-contact-submit')"
    >
      <form @submit.prevent="store">
        <label class="link">النص</label>
        <form-input id="info" placeholder="النص"></form-input>
        <button hidden id="add-contact-submit"></button>
      </form>
    </confirm-model>
  </teleport>
</template>

<script>
import BaseCard from "../../UI/BaseCard";
import FormInput from "../../Forms/FormInput";
import FormTextArea from "../../Forms/FormTextArea";
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import infoList from "@/mixins/infoList";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import AuctionsMap from "@/components/Info/AuctionsMap";

export default {
  components: {
    AuctionsMap,
    ItemsDestroyer,
    ConfirmModel,
    ModalBtn,
    FormTextArea,
    FormInput,
    BaseCard,
  },
  mixins: [infoList],
  computed: {
    authorized() {
      return this.$store.getters.authorized;
    },
    depart() {
      return 1;
    },
  },
  methods: {
    async feedback(e) {
      const formData = new FormData(e.target);

      if (!formData) return;

      if (!!this.authorized) {
        if (!!this.authorized.name) formData.set("name", this.authorized.name);

        if (!!this.authorized.email)
          formData.set("email", this.authorized.email);
      }
      if (
        !formData.get("name") ||
        !formData.get("email") ||
        !formData.get("msg")
      )
        return http.popupMessage("error", messages.checkFields);

      const response = await http.send("feedback", formData);
      if (http.responseAccepted(response)) {
        http.popupMessage("success", "تم الإرسال بنجاح");
        $_(".empty-on-submit").val("");
      }
    },
  },
  async created() {
    this.page = "contact";
    await this.setInfo();
  },
};
</script>
