<template>
  <iframe
    sameSite="Strict"
    width="100%"
    height="250"
    class="border-0"
    loading="lazy"
    allowfullscreen
    referrerpolicy="no-referrer-when-downgrade"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3395.2480971422588!2d35.968320299999995!3d31.6817849!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151b551118c7ec75%3A0xb582e30cdfd33faa!2z2KfZhNin2LHYr9mG2YrYqSDYp9mE2KfZiNmE2Ykg2YTZhNmF2LLYp9iv2KfYqg!5e0!3m2!1sen!2sjo!4v1652728342987!5m2!1sen!2sjo"
  />
</template>

<script>
export default {
  name: "AuctionsMap"
}
</script>